<template>
  <q-layout view="hHh LpR fFf" :class="isMobile ? 'MainLayoutM' : 'MainLayout'">
    <q-header reveal elevated>
      <q-toolbar class="toolbar">
        <q-btn
          flat
          dense
          round
          icon="menu"
          aria-label="Menu"
          @click="leftDrawerOpen = !leftDrawerOpen"
        />

        <q-toolbar-title>
          <div class="text-h6">
            Aprovações Cicopal
          </div>
          <div class="text-subtitle2" v-if="pageTitle">
            {{ pageTitle }}
          </div>
        </q-toolbar-title>

        <div>v{{ appVersion }}</div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      overlay
      behavior="mobile"
      elevated
      content-class="bg-grey-1"
      class="toolbar"
    >
      <q-list>
        <q-item-label header class="text-h6 text-grey-9 text-bold"
          >Olá {{ loggedInUser.name }}
        </q-item-label>
        <DrawerLink
          v-for="link in filteredLinks"
          :key="link.title"
          v-bind="link"
        />
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page class="main fit row wrap justify-center">
        <router-view />
      </q-page>
    </q-page-container>

    <!-- <q-footer reveal elevated class="toolbar text-white text-center">
      <q-toolbar>
        <q-toolbar-title>
          <span class="text-caption foot"
            >©Copyright 2020 Grupo Cicopal - Todos os direitos reservados</span
          >
        </q-toolbar-title>
      </q-toolbar>
    </q-footer> -->
  </q-layout>
</template>

<script>
import DrawerLink from "../components/DrawerLink";
import { version } from "../../package.json";
import { mapState, mapGetters } from "vuex";

export default {
  name: "MainLayout",
  components: { DrawerLink },
  data() {
    return {
      leftDrawerOpen: false
    };
  },
  computed: {
    ...mapState("users", ["loggedInUser"]),
    ...mapGetters("shared", ["appLinks"]),
    isMobile() {
      return this.$q.screen.xs;
    },
    appVersion() {
      return version;
    },
    pageTitle() {
      return this.$route.meta.caption || "";
    },
    filteredLinks() {
      if (this.loggedInUser.isAdministrator) {
        return this.appLinks.filter(link => link.showDrawer);
      }
      return this.appLinks.filter(
        link => link.showDrawer && !link.onlyAdministrator
      );
    }
  }
};
</script>
<style lang="scss">
.MainLayout {
  .toolbar {
    background: #1e22a8;
  }
  .foot {
    font-size: 0.7rem;
  }
  .main {
    background: #f7f7f7;
    background-image: url("../assets/logo.png");
    background-repeat: no-repeat;
    background-size: 80vw;
    background-position: center;
    background-clip: border-box;
  }
}

.MainLayoutM {
  .toolbar {
    background: #1e22a8;
  }
  .foot {
    font-size: 0.7rem;
  }
  .main {
    background: #f7f7f7;
  }
}
</style>
